import AppChrome from '../../components/AppChrome'
import HeroIllustration from '../../components/heroes/HeroIllustration'
import PageSegments from '../../components/pageSegments/PageSegments'

export { getServerSideProps } from '../../graphql/homepage'

function BrixelHomepagePage( { entry: homepage } ) {
  const illi = homepage.heroIllustration[ 0 ]

  return (
    <div>
      <AppChrome>
        <HeroIllustration 
          title={ homepage.mainTitle }
          subtitle={ homepage.subtitle }
          image={ illi.image[ 0 ] }
          objectPosition={ illi.objectPosition }
          objectFit={ illi.objectFit }
          imagePosition={ illi.horizontalAlignment }
          bgColor={ illi.backgroundColor }
          containerCSS={ illi.containerCss }
          buttonLabel={ homepage.buttonLabel }
          buttonLink={ homepage.buttonLink }
          buttonColor={ illi.buttonColor }
          buttonIcon={ homepage.buttonIcon }
          partnerLabel={ homepage.partnerLabel }
          partnerLogos={ homepage.logos }
          testimonial={ homepage.testimonial[ 0 ] }
        />
        <PageSegments pageSegments={ homepage.pageSegments } />
      </AppChrome>
    </div>
  )
}

export default BrixelHomepagePage
